'use strict';
[
"                                                                     ▄╗▄        ",
"                             ███░      ┌▄╗▓                    ▄▄╗   ███        ",
"  ┌▄▄▄┌┌┌┌  ┌▄▄▄     ┌▄▄▄┌   ███░┌┌┌┌┌┌████┌┌ ┌┌▄▄▄┌    ┌▄▄▄ ┌▄███▄┌ ▄▄▄    ▄▄▄┌",
"┌███▀█████┌███▀███ ┌███▀███  ███░████ ██████═ ███████ ▐█████▌║█████▀ ███░ ╫█████",
"▐███ ████ ████▄███▌╫███▄████ ███████   ████  ┌▄▓▀████ ▀███▓▄  ▐███   ███░┌███═  ",
" ██████▀  ████▀▀▀▀╙╫███▀▀▀▀▀ ███▌███▌  ████  ███▌▐███   ▀████ ▐███   ███░ ███▄  ",
"╘██████▓╗  ███████  ███████  ███░╘███▄ █████▌████████ ╫██████  █████ ███░ ▀█████",
"╫███└└████   └└└└     └└└└        └└└└   └└    └  └└└  └╙╙╙     └└└         └╙╙└",
"╜███████▀                                                                       "]
.forEach((e) => console.log(e));console.log("Developer? Want to get paid to review code?");console.log("Get access to exciting jobs from leading technology companies?");console.log("Than join Geektastic at https://app.geektastic.com/register");console.log("Have any questions, noticed any bugs, drop a message to rot13(uryyb@trrxgnfgvp.pbz)");